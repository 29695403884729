import React from 'react';
import { useSelector } from 'react-redux';

const ProfileImage = (props) => {
	const avatar = props.avatar;
	const swidth = props.picsize * 1.33;
	const business = props.business;
	const id = props.id;

	const defURL = "https://ipsg.ge/p/images/profile/";

	const defImage = business ? "default-business.png" : "default-personal.png";

	const profileImage = defURL + (avatar ? id + "/" + avatar : defImage);

	return (
		<div
			style={{
				borderRadius: '50%',
				backgroundImage: `url(${profileImage})`,
				width: `${props.picsize}px`,
				height: `${props.picsize}px`,
				backgroundSize: `${swidth}px`,
				backgroundPosition: 'center',
				border: '1px solid #6381a8',
			}}
		/>
	);
};
export default ProfileImage;
