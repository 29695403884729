import React from 'react';
import { Link, useParams } from 'react-router-dom';

import SiteHeader from '../Layouts/SiteHeader';
import ProfileNavbar from '../Profile/Partials/ProfileNavbar';
import ProfileHome from './ProfileHome';
import ProfileSessionHistory from './ProfileSessionHistory';
import ProfileBusinesses from './ProfileBusinesses';

const Profile = (props) => {
	const myParams = useParams();

	const showPage = () => {
		switch (myParams.page) {
			case 'data':
				return <ProfileHome />;
			case 'businesses':
				return <ProfileBusinesses />;
			case 'session-history':
				return <ProfileSessionHistory />;
			case 'settings':
				return <ProfileSessionHistory />;
			default:
				return <ProfileHome />;
		}
	};

	return (
		<div>
			<SiteHeader active="profile" />
			<div className="view-wrapper is-webapp">
				<div className="page-content-wrapper">
					<div className="page-content is-relative">
						<div className="page-content-inner">
							<div className="account-wrapper">
								<div className="columns">
									<ProfileNavbar />
									{showPage()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Profile;
