import React, { useState, useEffect } from 'react';

import ApiServices from '../../APP/ApiServices';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImage from '../Layouts/Partials/ProfileImage';
import { TextField } from '@mui/material';

import { IconUser } from '@tabler/icons-react';
import { IconGenderBigender } from '@tabler/icons-react';
import { IconCalendarDue } from '@tabler/icons-react';
import { IconHash } from '@tabler/icons-react';
import { IconId } from '@tabler/icons-react';
import { IconPhone } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import { IconMapPin } from '@tabler/icons-react';

import { useFetching } from '../../../hooks/useFetching';
import { FileUploader } from 'react-drag-drop-files';

import dayjs from 'dayjs';
import 'dayjs/locale/ka';
import locale from 'antd/es/date-picker/locale/ka_GE';

const docTypes = {
	'': 'დოკუმენტის ტიპი',
	id: 'პირადობის მოწმობა',
	passport: 'პასპორტი',
};

const ProfileBusiness = (props) => {
	// const [userDoc, setUserDoc] = useState({});
	// const userDetails = useSelector((state) => state.toolkit.userDetails);
	const userBusiness = useSelector((state) => state.toolkit.userBusiness);
	const [updUB, setUpdUB] = useState({});

	const token = useSelector((state) => state.toolkit.token);

	const [fetching, isLoading, error] = useFetching(async (param) => {
		const response = await param;
		return response;
	});

	const handleAvatarUpload = async (obj, objName) => {
		const conf = { token: token, id: userBusiness.bID, tb: 'business', objName: objName };
		const formData = new FormData();
		formData.append('myfile', obj);
		formData.append('params', JSON.stringify(conf));
		const response = await fetching(ApiServices.uploadFile(formData));
		if (response.data.success === 'success') {
			sessionStorage.setItem("userBusiness", JSON.stringify({ ...userBusiness, avatar: response.data.filename }));
			window.location.reload(false);
		}
	};

	const updateDB = async () => {
		// first needs validate
		const request = { function: 'updateBusiness', token: token, bID: userBusiness.bID, pars: updUB }
		console.log(request);
	}

	const updateValues = (e) => {
		setUpdUB({ ...updUB, [e.target.id]: e.target.value })
	}

	useEffect(() => {
		setUpdUB({
			phone: userBusiness.phone,
			email: userBusiness.email,
			address: userBusiness.address,
		});
		// console.log('changed', updUB);
	}, [userBusiness]);

	// console.log(userBusiness);

	return (
		<div className="account-box is-form is-footerless">
			<div className="form-head stuck-header">
				<div className="form-head-inner">
					<div className="left">
						<h3 className="font">ორგანიზაციის მონაცემები</h3>
					</div>
					<div className="right">
						<div className="buttons">
							<button
								id="save-button"
								className="button h-button is-primary is-raised font is-rounded"
								onClick={updateDB}
							>
								ცვლილების შენახვა
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="form-body">
				<div className="fieldset">
					<div className="h-avatar profile-h-avatar is-xl" title="სურათის ატვირთვა">
						<FileUploader
							handleChange={(obj) => handleAvatarUpload(obj, 'avatar')}
							types={['JPG', 'JPEG', 'PNG']}
							multiple={false}
							maxSize={1}
						>
							<ProfileImage
								picsize={150}
								id={userBusiness.bID}
								avatar={userBusiness.avatar}
								business={true}
							/>
						</FileUploader>
					</div>
				</div>
				<div className="fieldset">
					<div className="fieldset-heading">
						<h4 className="font">დეტალები</h4>
					</div>
					<div className="columns is-multiline">
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										value={userBusiness.short_name}
										className="input font-p"
										placeholder="ორგანიზაციის ტიპი"
										readOnly
									/>
									<div className="form-icon">
										<IconUser />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										value={userBusiness.legal_name}
										className="input font-p"
										placeholder="დასახელება"
										readOnly
									/>
									<div className="form-icon">
										<IconUser />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="fieldset">
					<div className="fieldset-heading">
						<h4 className="font">საიდენტიფიკაციო ნომერი</h4>
					</div>
					<div className="columns is-multiline">
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										value={userBusiness.registration_id}
										className="input font-p"
										placeholder="საიდენტიფიკაციო ნომერი"
										readOnly
									/>
									<div className="form-icon">
										<IconHash />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue=""
										className="input font-p"
										placeholder=""
										readOnly
									/>
									<div className="form-icon">
										<IconCalendarDue />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="fieldset">
					<div className="fieldset-heading">
						<h4 className="font">კონტაქტი</h4>
					</div>
					<div className="columns is-multiline">
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="number"
										id="phone"
										value={updUB.phone}
										className="input font-p"
										placeholder="ტელეფონი"
										onChange={updateValues}
									/>
									<div className="form-icon">
										<IconPhone />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										id="email"
										value={updUB.email}
										className="input font-p"
										placeholder="ელ ფოსტა"
										onChange={updateValues}
									/>
									<div className="form-icon">
										<IconMail />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-12">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										id="address"
										value={updUB.address}
										className="input"
										placeholder="მისამართი"
										onChange={updateValues}
									/>
									<div className="form-icon">
										<IconMapPin />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileBusiness;
