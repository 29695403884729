import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ProfileBusiness from './ProfileBusiness';
import ProfilePersonal from './ProfilePersonal';

const ProfileHome = (props) => {
	const userBusiness = useSelector((state) => state.toolkit.userBusiness);

	return (
		<div className="column is-8">
			{userBusiness ? <ProfileBusiness /> : <ProfilePersonal />}
		</div>
	);
}

export default ProfileHome;
