import React from 'react';
import { Link, useParams } from 'react-router-dom';

import SiteHeader from '../Layouts/SiteHeader';
import PaymentNavbar from './Partials/PaymentNavbar';
import PaymentFavoritePay from './PaymentsFavorirePay';
import PaymentsFavorites from './PaymentsFavorires';
import PaymentsHistory from './PaymentsHistory';
import PaymentsProviders from './PaymentsProviders';

const Payments = (props) => {
	const myParams = useParams();

	const showPage = () => {
		switch (myParams.page) {
			case 'favorites':
				return <PaymentsFavorites />;
			case 'history':
				return <PaymentsHistory />;
			case 'favorites/pay':
				return <PaymentFavoritePay />;
			default:
				return <PaymentsProviders />;
		}
	};
	return (
		<div>
			<SiteHeader active="payments" />
			<div className="view-wrapper is-webapp">
				<div className="page-content-wrapper">
					<div className="page-content is-relative">
						<div className="page-content-inner">
							<div className="account-wrapper">
								<div className="columns">
									<PaymentNavbar />
									{showPage()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Payments;
