import React, { useState } from 'react';
import ReactCodeInput from 'react-code-input';

import { Button } from '@mui/material';

const LoginCode = (props) => {
	const [loginButtonOK, setLoginButtonOK] = useState(false);
	const [confirmCode, setConfirmCode] = useState();

	const [seconds, setSeconds] = React.useState(60);

	React.useEffect(() => {
		if (seconds > 0) {
			setTimeout(() => setSeconds(seconds - 1), 1000);
		} else {
			setSeconds(
				<span
					className="hint--light hint--top font"
					data-hint="ახალი კოდის მიღება"
				>
					<i className="lnil lnil-reload text-primary"></i>
				</span>
			);
		}
	});

	const handleCodeChange = (e) => {
		// console.log(e);
		if (e.length == 4) {
			setConfirmCode(e);
			setLoginButtonOK(true);
		} else {
			setLoginButtonOK(false);
		}
	};

	const handleSubmit = (e) => {
		if (loginButtonOK) {
			props.submitCodeForm({ code: confirmCode });
		}
	};

	const progress = seconds * 1.6;

	return (
		<div className="side-wrapper auth-width">
			<div className="side-inner p-6">
				<h3 className="fs-24 font has-text-centered mb-3 title is-5 mt-5">
					ერთჯერადი კოდი
				</h3>
				<div className="columns">
					<div className="column is-10 pt-5">
						<progress
							className="progress is-primary is-tiny"
							value={progress}
							max="100"
						>
							0
						</progress>
					</div>
					<div className="column is-2">
						<div className="h-avatar is-small">
							<span className="avatar is-fake">
								<span>{seconds}</span>
							</span>
						</div>
					</div>
				</div>
				<div className="has-text-centered">
					<ReactCodeInput
						type="number"
						fields={4}
						onChange={handleCodeChange}
						inputStyle={{
							WebKitAppearance: 'none',
							MozAppearance: 'textfield',
							marginRight:'12px',
							height: '38px',
							width:'38px',
							backgroundColor: '#fff',
							borderRadius:'8px',
							border: '1px solid #dbdbdb',
							textAlign: 'center',
							color: '#3c3c3c',
							fontFamily: 'StafiloBold',
							fontSize:'16px',
						}}
					/>
				</div>
				<div className="control login">
					<Button
						className="button h-button is-primary is-bold is-fullwidth is-raised is-rounded font mt-5"
						disabled={!loginButtonOK}
						onClick={handleSubmit}
					>
						შესვლა / რეგისტრაცია
					</Button>
				</div>
			</div>
		</div>
	);
};

export default LoginCode;
