import React from 'react';
import { Link } from 'react-router-dom';

const SignInBooble = (props) => {

	return (
		<div className="dropdown profile-dropdown dropdown-trigger is-spaced is-right">
			<Link to='/auth/login' className="button h-button is-rounded is-primary font">სისტემაში შესვლა</Link>
		</div>

	);
};

export default SignInBooble;