import React from 'react';

import SiteHeader from '../Layouts/SiteHeader';

const MailBox = (props) => {
	return (
		<div>
			<SiteHeader active="mailbox" />
			<div
				id="mailbox"
				className="view-wrapper is-webapp"
				data-page-title="საფოსტო ყუთი"
				data-naver-offset="214"
				data-menu-item="#profile"
				data-mobile-item="#home-sidebar-menu-mobile"
			>
				<div className="chat-app-wrapper w-1200 br-16 chat-height">
					<div className="wrapper">
						<div className="conversation-area" data-simplebar>
							<div className="conversation">
								<a className="button compose-button is-fullwidth font is-primary is-rounded">
									ახალი შეტყობინება
								</a>
							</div>

							<div className="conversation active">
								<div className="h-avatar">
									<span className="avatar is-fake is-h-purple">
										<span className="font">აქ</span>
									</span>
								</div>
								<div className="conversation-detail">
									<div className="conversation-username font">
										თემის სათაური
									</div>
									<div className="conversation-content">
										<span className="conversation-message">
											შეტყობინების ტექსტი
										</span>
										<span className="conversation-date"> 22:00</span>
									</div>
								</div>
								<button
									className="button is-circle is-elevated ml-5"
									style={{ position: 'absolute', right: '20px' }}
								>
									<span className="icon is-small">
										<i data-feather="trash-2"></i>
									</span>
								</button>
							</div>

							<div
								className="conversation"
								data-conversation-id="4"
								data-detail-name="Alice Carasca"
								data-detail-text="Software Engineer"
							>
								<div className="h-avatar">
									<img
										className="avatar"
										src="https://via.placeholder.com/150x150"
										data-demo-src="/assets/img/avatars/photos/7.jpg"
										alt=""
										data-user-popover="0"
									/>
								</div>
								<div className="conversation-detail">
									<div className="conversation-username font">
										თემის სათაური
									</div>
									<div className="conversation-content">
										<span className="conversation-message">
											შეტყობინების ტექსტი
										</span>
										<span className="conversation-date"> 22:00</span>
									</div>
								</div>
								<button
									className="button is-circle is-elevated ml-5 "
									style={{ position: 'absolute', right: '20px' }}
								>
									<span className="icon is-small">
										<i data-feather="trash-2"></i>
									</span>
								</button>
							</div>
						</div>

						<div className="chat-area pb-4" data-simplebar>
							<div
								id="webapp-conversation-3"
								className="chat-area-content is-active"
							>
								<div className="chat-area-header">
									<button className="trigger conversations-mobile-trigger h-only-mobile h-only-tablet-p h-only-tablet-l">
										<i data-feather="menu"></i>
									</button>
									<div className="chat-area-title font">
										თემის სათაური
										<div className="field">
											<div className="select is-rounded">
												<select className="font">
													<option>აირჩიე თემა</option>
													<option>თემა 1</option>
												</select>
											</div>
										</div>
									</div>
									<div className="chat-area-group"></div>
								</div>
								<div className="chat-area-main">
									<div className="chat-msg">
										<div className="chat-msg-profile">
											<img
												className="chat-msg-img"
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/photos/19.jpg"
												alt=""
												data-user-popover="17"
											/>
											<div className="chat-msg-date">Sent at 4:27pm</div>
										</div>
										<div className="chat-msg-content">
											<div className="chat-msg-text">
												I know this one, we were using it in my previous
												company. It's reliable and fast 😊.
											</div>
											<div className="chat-msg-text">
												I mean it's worth a try and we're in need right now.
											</div>
										</div>
									</div>
									<div className="chat-msg owner">
										<div className="chat-msg-profile">
											<img
												className="chat-msg-img"
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/photos/8.jpg"
												alt=""
												data-user-popover="3"
											/>
											<div className="chat-msg-date">Sent at 4:31pm</div>
										</div>
										<div className="chat-msg-content">
											<div className="chat-msg-text">
												Yeah but I need to test some use cases.
											</div>
											<div className="chat-msg-text">
												Can't sign up before being sure.
											</div>
										</div>
									</div>
									<div className="chat-msg">
										<div className="chat-msg-profile">
											<img
												className="chat-msg-img"
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/photos/12.jpg"
												alt=""
												data-user-popover="2"
											/>
											<div className="chat-msg-date">Sent at 4:32pm</div>
										</div>
										<div className="chat-msg-content">
											<div className="chat-msg-text">
												Haha, don't be scare Erik
											</div>
										</div>
									</div>
									<div className="chat-msg">
										<div className="chat-msg-profile">
											<img
												className="chat-msg-img"
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/photos/7.jpg"
												alt=""
												data-user-popover="0"
											/>
											<div className="chat-msg-date">Sent at 4:32pm</div>
										</div>
										<div className="chat-msg-content">
											<div className="chat-msg-text">
												Yay, Erik going chicken mode again 😂😂😂
											</div>
										</div>
									</div>
									<div className="chat-msg">
										<div className="chat-msg-profile">
											<img
												className="chat-msg-img"
												src="https://via.placeholder.com/150x150"
												data-demo-src="assets/img/avatars/photos/7.jpg"
												alt=""
												data-user-popover="0"
											/>
											<div className="chat-msg-date">Sent at 4:32pm</div>
										</div>
										<div className="chat-msg-content">
											<div className="chat-msg-text">
												Yay, Erik going chicken mode again 😂😂😂
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="field has-addons m-4">
								<div className="control is-expanded">
									<input
										className="input is-rounded font-p"
										type="text"
										placeholder="თანხა"
									/>
								</div>
								<div className="control">
									<a className="button  is-rounded font">
										<i data-feather="send"></i>
									</a>
								</div>
							</div>
							{/* <div className="chat-area-footer w-1200">
                            <div className="add-content">
                                <div className="dropdown dropdown-trigger is-up">
                                    <div>
                                        <div className="button" aria-haspopup="true">
                                            <i data-feather="plus"></i>
                                        </div>
                                    </div>
                                    <div className="dropdown-menu" role="menu">
                                        <div className="dropdown-content">
                                            <a className="dropdown-item">
                                                <i data-feather="video"></i>
                                                <div className="meta">
                                                    <span>Video</span>
                                                    <span>Embed a video</span>
                                                </div>
                                            </a>
                                            <a href="#" className="dropdown-item kill-drop h-modal-trigger">
                                                <i data-feather="image"></i>
                                                <div className="meta">
                                                    <span>Images</span>
                                                    <span>Upload pictures</span>
                                                </div>
                                            </a>
                                            <a href="#" className="dropdown-item kill-drop h-modal-trigger">
                                                <i data-feather="link"></i>
                                                <div className="meta">
                                                    <span>Link</span>
                                                    <span>Post a link</span>
                                                </div>
                                            </a>
                                            <hr className="dropdown-divider" />
                                            <a href="#" className="dropdown-item kill-drop h-modal-trigger">
                                                <i data-feather="file"></i>
                                                <div className="meta">
                                                    <span>File</span>
                                                    <span>Upload a file</span>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="add-emoji">
                                <div className="button">
                                    <i data-feather="smile"></i>
                                </div>
                            </div>
                            <input type="text" className='font-p' placeholder="თქვენი შეტყობინება ..." />
                        </div> */}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default MailBox;
