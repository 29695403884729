import { createAction, createReducer } from "@reduxjs/toolkit";

const initialState = {
	darkMode: false,
	styleName: "themeLight",
	muiTheme: "light",
	isLoading: false,
	token: false,
	userDetails: false,
	userBusiness: false,
	unreadedMessages: 0,
};

export const setDarkMode = createAction("setMode");
export const setIsLoading = createAction("isLoading");
export const setToken = createAction("token");
export const setUserDetails = createAction("userDetails");
export const setUserBusiness = createAction("userBusiness");
export const setUnreadedMessages = createAction("unreadedMessages");

export default createReducer(initialState, (builder) => {
	builder
		.addCase(setDarkMode, (state, action) => {
			state.darkMode = action.payload;
			state.styleName = state.darkMode ? "themeDark" : "themeLight";
			state.muiTheme = state.darkMode ? "dark" : "light";
		})
		.addCase(setIsLoading, (state, action) => {
			state.isLoading = action.payload;
		})
		.addCase(setToken, (state, action) => {
			// const [cookies, setCookie] = useCookies('darkMode');
			state.token = action.payload;
			// setCookie('token', darkMode);
		})
		.addCase(setUserDetails, (state, action) => {
			state.userDetails = action.payload;
		})
		.addCase(setUserBusiness, (state, action) => {
			// localStorage.setItem("userBusiness", state.action.payload);
			state.userBusiness = action.payload;
		})
		.addCase(setUnreadedMessages, (state, action) => {
			state.unreadedMessages = action.payload;
		})
		.addDefaultCase((state, action) => {});
});

// export default createReducer(initialState, (builder) => {
// 	builder
// 		.addCase(setDarkMode, (state, action) => {
// 			state.darkMode = action.payload;
// 			state.styleName = state.darkMode ? "themeDark" : "themeLight";
// 			state.muiTheme = state.darkMode ? "dark" : "light";
// 		})
// 		.addCase(setIsLoading, (state, action) => {
// 			state.isLoading = action.payload;
// 		})
// 		.addCase(setToken, (state, action) => {
// 			state.token = action.payload;
// 		})
// 		// .addCase(setInvalidToken, (state, action) => {
// 		// 	state.invalidToken = action.payload;
// 		// })
// 		.addCase(setOperatorDetails, (state, action) => {
// 			state.operatorDetails = action.payload;
// 		})
// 		.addDefaultCase((state, action) => {});
// });
