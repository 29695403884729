import React from 'react';

import SiteHeader from '../Layouts/SiteHeader';
import E404 from '../../../assets/picture/errors/404.png';

const e404 = (props) => {
	return (
		<div>
			<SiteHeader />
			<div
				id="edit-profile"
				className="view-wrapper is-webapp"
				data-page-title="404"
				data-naver-offset="214"
				data-menu-item="#profile"
				data-mobile-item="#home-sidebar-menu-mobile"
			>
				<div className="page-content-wrapper">
					<div className="page-content is-relative">
						<div className="page-content-inner">
							<div className="account-wrapper">
								<div className="columns">
									<div className="column is-2"></div>
									<div className="column is-8">
										<div className="error-wrapper">
											<div className="error-inner has-text-centered">
												<img className="light-image" src={E404} alt="" />
												<img className="dark-image" src={E404} alt="" />
												<h1 className="dark-inverted mb-5">404</h1>
												<p className="font-p mb-5">გვერდი ვერ მოიძებნა!</p>
												<div className="button-wrap">
													<a
														className="button h-button is-primary is-elevated font"
														href="/"
													>
														მთავარი გვერდი
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default e404;
