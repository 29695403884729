import React from 'react';
import { Link, useParams } from 'react-router-dom';

import SiteHeader from '../Layouts/SiteHeader';
import PaymentNavbar from './Partials/PaymentNavbar';

const providers = [
	{
		id: 1,
		category: 'telecom',
		name: 'ტელეკომ1',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 2,
		category: 'telecom',
		name: 'დატაკომი',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 3,
		category: 'telecom',
		name: 'ფლაინეტი',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 4,
		category: 'telecom',
		name: 'სისტემნეტი',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 5,
		category: 'mobilphone',
		name: 'მაგთი',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 6,
		category: 'mobilphone',
		name: 'სილქნეტი',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 7,
		category: 'mobilphone',
		name: 'ბილაინი',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 8,
		category: 'bills',
		name: 'ენერგო-პრო',
		avatar: 'https://via.placeholder.com/150x150',
	},
	{
		id: 9,
		category: 'bills',
		name: 'გაერთიანებული წყალმომარაგება',
		avatar: 'https://via.placeholder.com/150x150',
	},
];

const providerBox = (data) => {
	return (
		<div className="s-card-advanced br-16">
			<Link to="/payment/show">
				<div className="card-head">
					<div className="left">
						<div className="media-flex-center no-margin">
							<div className="h-avatar {data.avatar}-medium">
								<img className="avatar" src={data.avatar} alt="" />
							</div>
							<div className="ml-2, flex{data.avatar}eta">
								<span className="font">{data.name}</span>
							</div>
						</div>
					</div>
				</div>
			</Link>
		</div>
	);
};

const PaymentsProviders = (props) => {
	const myParams = useParams();
	const pg = myParams.page ? myParams.page : 'telecom';

	return (
		<div className="column is-8">
			<div className="columns is-multiline">
				{providers.map(
					(option, i) =>
						option.category === pg && (
							<div key={i} className="column is-4">
								{providerBox(option)}
							</div>
						)
				)}
			</div>
		</div>
	);
};

export default PaymentsProviders;
