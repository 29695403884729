import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import { useDispatch, useSelector } from 'react-redux';
import { setToken } from '../../../../toolkitRedux/toolkitReducer';
import { setUserDetails } from '../../../../toolkitRedux/toolkitReducer';
import { setUserBusiness } from '../../../../toolkitRedux/toolkitReducer';
import ProfileImage from './ProfileImage';

import { IconPlaylistAdd, IconUserCircle } from '@tabler/icons-react';

const ProfileDropdown = (props) => {
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const userBusiness = useSelector((state) => state.toolkit.userBusiness);
	// const userBusiness = JSON.parse(sessionStorage.getItem("userBusiness"));
	const [cookies, setCookie, removeCookie] = useCookies();
	const dispatch = useDispatch();

	const signOut = async () => {
		removeCookie('_ut_ipsg_');
		dispatch(setToken('unauth'));
		dispatch(setUserDetails({}));
		dispatch(setUserBusiness(false));
	};

	const selectBusiness = (data) => {
		sessionStorage.setItem("userBusiness", JSON.stringify(data));
		dispatch(setUserBusiness(data));
		// window.location.reload(false);
		// document.getElementsByClassName('dropdown-profile-content')[0].style.visibility = 'hidden';
	};

	const closeBusiness = () => {
		sessionStorage.removeItem("userBusiness");
		dispatch(setUserBusiness(false));
		// window.location.reload(false);
	};

	return (
		<div className="dropdown-profile">
			{userBusiness ?
				<div>
					<div className="float-left">
						<ProfileImage picsize={50} id={userBusiness.bID} avatar={userBusiness.avatar} business={true} />
					</div>
					<div className="pl-3 drodmenu">
						<div className="font pt-1">{userBusiness.short_name} "{userBusiness.legal_name}"</div>
						<div className="font-p">{userBusiness.registration_id}</div>
					</div>
				</div>
				:
				<div>
					<div className="float-left">
						<ProfileImage picsize={50} id={userDetails.uID} avatar={userDetails.avatar} business={false} />
					</div>
					<div className="pl-3 drodmenu">
						<div className="font pt-1">{userDetails.firstName} {userDetails.lastName}</div>
						<div className="font-p">{userDetails.pid}</div>
					</div>
				</div>
			}
			<div className="dropdown-profile-content">
				<div className="dropdown-content p-3">
					<Link to="/profile" >
						<div className="h-avatar is-medium profile-icon">
							{userBusiness ?
								<ProfileImage picsize={40} id={userBusiness.bID} avatar={userBusiness.avatar} business={true} />
								:
								<ProfileImage picsize={40} id={userDetails.uID} avatar={userDetails.avatar} business={false} />
							}
						</div>
						<div className="meta pt-5">
							<div className="font profile-meta">
								<p className="font">
									პროფილი
								</p>
							</div>
						</div>

					</Link>
					<hr className="dropdown-divider" />
					{userBusiness && (
						<>
							<Link to="#" onClick={closeBusiness}>
								<div className="dropdown-head">
									<div className="h-avatar is-medium profile-icon ">
										<ProfileImage picsize={40} id={userDetails.uID} avatar={userDetails.avatar} business={false} />
									</div>
									<div className="meta pt-2">
										<div className="font profile-meta">
											<p className="font">
												{userDetails.firstName} {userDetails.lastName}
											</p>
										</div>
										<span className="font-p">{userDetails.pid}</span>
									</div>
								</div>
							</Link>
							<hr className="dropdown-divider" />
						</>
					)}
					<Link to="/profile/businesses">
						<div className="profile-icon">
							<IconPlaylistAdd className="col5" />
						</div>
						<div className="profile-meta">
							<p className="font pt-3">იურიდიული პირი</p>
						</div>
					</Link>
					{userDetails.businesses && <hr className="dropdown-divider" />}
					{userDetails.businesses?.map(
						(data, i) =>
							data.bID !== userBusiness.bID && (
								<Link key={i} to="#" onClick={() => selectBusiness(data)}>
									<div className="h-avatar is-medium profile-icon">
										<ProfileImage picsize={40} id={data.bID} avatar={data.avatar} business={true} />
									</div>
									<div className="meta pt-3">
										<div className="font profile-meta">
											<p className="font">
												{data.short_name} "{data.legal_name}"
											</p>
										</div>
										<p className="font-p">{data.registration_id}</p>
									</div>
								</Link>
							)
					)}
					<hr className="dropdown-divider" />
					<div className="dropdown-item is-button">
						<button
							className="button h-button is-primary is-raised is-fullwidth is-rounded logout-button"
							onClick={signOut}
						>
							<span className="icon is-small">
								<i data-feather="log-out"></i>
							</span>
							<span className="font">გასვლა</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileDropdown;
