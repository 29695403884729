import React from 'react';

import PaymentNavbar from './Partials/PaymentNavbar';

const PaymentsHistory = (props) => {
	const history = [
		{
			id: '546546322',
			date: '30/01/2022',
			status: 'წარმატებული',
			card: '**** **** **** 3466',
			price: '50.00',
			url: '#',
		},
		{
			id: '546546322',
			date: '29/01/2022',
			status: 'წარმატებული',
			card: '**** **** **** 3466',
			price: '100.00',
			url: '#',
		},
		{
			id: '546546322',
			date: '28/01/2022',
			status: 'წარმატებული',
			card: '**** **** **** 3403',
			price: '250.00',
			url: '#',
		},
	];

	return (
		<div className="column is-8">
			<div className="s-card demo-table p-2">
				<script>bulmaCalendar.attach('#bulma-datepicker-4', {});</script>
				<div className="field mt-3">
					<div className="control">
						<input
							id="bulma-datepicker-4"
							className="input"
							type="date"
							data-is-range="true"
						/>
					</div>
				</div>
				<br></br>
				<table className="table is-hoverable is-fullwidth">
					<tbody>
						<tr>
							<th className="font">ID</th>
							<th className="font">თარიღი</th>
							<th className="font">ტრანზაქცია</th>
							<th className="font">ბარათი</th>
							<th className="font">თანხა</th>
							<th className="is-end font"> </th>
						</tr>
						{history.map((data) => (
							<tr>
								<td className="font-p">{data.id}</td>
								<td className="font-p">{data.date}</td>
								<td className="font-p">
									<div className="flex-table-cell" data-th="Status">
										<span className="tag is-success is-rounded font">
											{data.status}
										</span>
									</div>
								</td>
								<td className="font-p">{data.card}</td>
								<td className="font-p">{data.price} ₾</td>
								<td className="is-end font-p">
									<a href={data.url}>
										<i data-feather="eye"></i>
									</a>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default PaymentsHistory;
