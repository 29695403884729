import React, { useState, useEffect } from 'react';

import ApiServices from '../../APP/ApiServices';
import { useDispatch, useSelector } from 'react-redux';
import ProfileImage from '../Layouts/Partials/ProfileImage';
import { TextField } from '@mui/material';


import { IconUser } from '@tabler/icons-react';
import { IconGenderBigender } from '@tabler/icons-react';
import { IconCalendarDue } from '@tabler/icons-react';
import { IconHash } from '@tabler/icons-react';
import { IconId } from '@tabler/icons-react';
import { IconPhone } from '@tabler/icons-react';
import { IconMail } from '@tabler/icons-react';
import { IconMapPin } from '@tabler/icons-react';

import { useFetching } from '../../../hooks/useFetching';

import { FileUploader } from 'react-drag-drop-files';

import dayjs from 'dayjs';
import 'dayjs/locale/ka';
import locale from 'antd/es/date-picker/locale/ka_GE';

const docTypes = {
	'': 'დოკუმენტის ტიპი',
	id: 'პირადობის მოწმობა',
	passport: 'პასპორტი',
};

const ProfilePersonal = (props) => {
	const [userDoc, setUserDoc] = useState({});
	const userDetails = useSelector((state) => state.toolkit.userDetails);
	const token = useSelector((state) => state.toolkit.token);
	const [updUD, setUpdUD] = useState({});

	const [fetching, isLoading, error] = useFetching(async (param) => {
		const response = await param;
		return response;
	});

	const loadUserDocs = async () => {
		if (token && token !== 'unauth' && userDoc !== {}) {
			const request = {
				function: 'getUserDocument',
				token: token,
			};
			const response = await fetching(ApiServices.userApi(request));
			if (response.data.success === 'success') {
				setUserDoc(response.data.data);
			} else {
			}
		}
	};

	const handleAvatarUpload = async (obj, objName) => {
		const conf = { token: token, id: userDetails.uID, tb: 'personal', objName: objName };
		const formData = new FormData();
		formData.append('myfile', obj);
		formData.append('params', JSON.stringify(conf));
		const response = await fetching(ApiServices.uploadFile(formData));
		if (response.data.success === 'success') {
			window.location.reload(false);
		}
	};

	useEffect(() => {
		loadUserDocs();
	}, [token]);

	const updateDB = async () => {
		// first needs validate
		const request = { function: 'updateUser', token: token, uID: userDetails.uID, pars: updUD }
		console.log(request);
	}

	const updateValues = (e) => {
		setUpdUD({ ...updUD, [e.target.id]: e.target.value })
	}

	return (
		<div className="account-box is-form is-footerless">
			<div className="form-head stuck-header">
				<div className="form-head-inner">
					<div className="left">
						<h3 className="font">დეტალები</h3>
					</div>
					<div className="right">
						<div className="buttons">
							<button
								id="save-button"
								className="button h-button is-primary is-raised font is-rounded"
								onClick={updateDB}
							>
								ცვლილების შენახვა
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="form-body">
				<div className="fieldset">
					<div className="h-avatar profile-h-avatar is-xl" title="სურათის ატვირთვა">
						<FileUploader
							handleChange={(obj) => handleAvatarUpload(obj, 'avatar')}
							types={['JPG', 'JPEG', 'PNG']}
							multiple={false}
							maxSize={1}
						>
							<ProfileImage
								picsize={150}
								id={userDetails.uID}
								avatar={userDetails.avatar}
								business={false}
							/>
						</FileUploader>
					</div>
				</div>
				<div className="fieldset">
					<div className="fieldset-heading">
						<h4 className="font">პირადი მონაცემები</h4>
					</div>

					<div className="columns is-multiline">
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={userDetails?.firstName}
										className="input font-p"
										placeholder="სახელი"
										readOnly
									/>
									<div className="form-icon">
										<IconUser />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={userDetails?.lastName}
										className="input font-p"
										placeholder="გვარი"
										readOnly
									/>
									<div className="form-icon">
										<IconUser />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={
											userDetails?.gender === 'm'
												? 'მამრობითი'
												: 'მდედრობითი'
										}
										className="input font-p"
										placeholder="სქესი"
										readOnly
									/>
									<div className="form-icon">
										<IconGenderBigender />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={dayjs(userDetails?.birthday).format('DD/MM/YYYY')}
										className="input font-p"
										placeholder="დაბადების თარიღი"
										readOnly
									/>
									<div className="form-icon">
										<IconCalendarDue />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="fieldset">
					<div className="fieldset-heading">
						<h4 className="font">დოკუმენტი</h4>
					</div>

					<div className="columns is-multiline">
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={userDetails?.pid}
										className="input font-p"
										placeholder="პირადი ნომერი"
										readOnly
									/>
									<div className="form-icon">
										<IconHash />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={dayjs(userDoc?.document_expires).format('DD/MM/YYYY')}
										className="input font-p"
										placeholder="მოქმედების ვადა"
										readOnly
									/>
									<div className="form-icon">
										<IconCalendarDue />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={docTypes[userDoc?.document_type]}
										className="input font-p"
										placeholder="პირადობის მოწმობა"
										readOnly
									/>
									<div className="form-icon">
										<IconId />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										defaultValue={userDoc?.document_number}
										className="input font-p"
										placeholder="დოკუმენტის ნომერი"
										readOnly
									/>
									<div className="form-icon">
										<IconHash />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="fieldset">
					<div className="fieldset-heading">
						<h4 className="font">კონტაქტი</h4>
					</div>
					<div className="columns is-multiline">
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										readOnly={userDetails.authMethod === 'sms'}
										id="phone"
										defaultValue={userDetails.phone}
										className="input font-p"
										placeholder="ტელეფონი"
										onChange={updateValues}
									/>
									<div className="form-icon">
										<IconPhone />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-6">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										readOnly={userDetails.authMethod === 'email'}
										id="email"
										defaultValue={userDetails.email}
										className="input font-p"
										placeholder="ელ ფოსტა"
										onChange={updateValues}
									/>
									<div className="form-icon">
										<IconMail />
									</div>
								</div>
							</div>
						</div>
						<div className="column is-12">
							<div className="field">
								<div className="control has-icon">
									<input
										type="text"
										id="address"
										defaultValue={userDetails.address}
										className="input"
										placeholder="მისამართი"
										onChange={updateValues}
									/>
									<div className="form-icon">
										<IconMapPin />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfilePersonal;
