import React, { Component } from "react";
import axios from "axios";

export default class ApiServices extends Component {
	constructor() {
		super();
		this.state = {
			name: "Token",
			token: false,
			isLoading: false,
			isError: false,
		};
	}

	static setToken(pars) {
		this.token = pars;
	}

	static async userApi(pars) {
		this.isLoading = true;
		const obj = JSON.stringify(pars);
		const response = await axios.post("https://ipsg.ge/p/userApi.php", obj);
		this.isLoading = false;
		return response;
	}

	static async registerApi(pars) {
		this.isLoading = true;
		const obj = JSON.stringify(pars);
		const response = await axios.post(
			"https://ipsg.ge/p/userRegister.php",
			obj
		);
		this.isLoading = false;
		return response;
	}

	static async uploadFile(obj) {
		const response = await axios.post("https://ipsg.ge/p/uploadFile.php", obj);
		return response;
	}
}
