import React from 'react';
import { Link, useParams } from 'react-router-dom';

const PaymentNavbar = (props) => {
	const myParams = useParams();
	const pg = myParams.page ? myParams.page : 'telecom';

	const navBar = [
		{
			type: 'link',
			hide: false,
			icon: 'lnil lnil-star',
			name: 'რჩეულები',
			url: '/payments/favorites',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'divider',
		},
		{
			type: 'link',
			icon: 'lnil lnil-rss-feed',
			name: 'ინტერნეტი ტელეფონი ტელევიზია',
			url: '/payments/telecom',
			class: 'account-menu-item ',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-mobile-alt-1',
			name: 'მობილური',
			url: '/payments/mobilphone',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-home',
			name: 'კომუნალურები',
			url: '/payments/bills',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-credit-card',
			name: 'ბანკები დაზღვევა მიკროსაფინანსო',
			url: '/payments/bank-insurence',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			hide: true,
			icon: 'lnil lnil-bank',
			name: 'სახელმწიფო სერვისები',
			url: '/payments/state-treasury',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-car-alt',
			name: 'ტრანსპორტი',
			url: '/payments/transport',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-graduate-alt',
			name: 'განათლება',
			url: '/payments/education',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-delivery',
			name: 'ამანათები',
			url: '/payments/parcels',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'link',
			icon: 'lnil lnil-more-alt',
			name: 'მეტი',
			url: '/payments/more',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
		{
			type: 'divider',
		},
		{
			type: 'link',
			icon: 'lnil lnil-text-align-justify',
			name: 'გადახდების ისტორია',
			url: '/payments/history',
			class: 'account-menu-item',
			activeClass: 'account-menu-item is-active',
		},
	];
	return (
		<div className="column is-4">
			<div className="account-box is-navigation br-16">
				<div className="account-menu">
					{navBar.map((data, i) => (
						<div key={i}>
							{/* {console.log("data.url:", data.url); console.log("current
							selection:", '/payments/' + myParams.id);} */}
							{data.type === 'link' && !data.hide ? (
								<Link
									to={data.url}
									className={
										data.url === '/payments/' + pg
											? data.activeClass
											: data.class
									}
								>
									<i className={data.icon}></i>
									<span className="font">{data.name}</span>
								</Link>
							) : data.type === 'divider' && (
								<div className="dropdown-divider " />
							)}
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default PaymentNavbar;
