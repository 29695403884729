import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../UI/Home";
import PageAbout from "../UI/Pages/About";
import PageContact from "../UI/Pages/Contact";
import E404 from "../UI/Errors/404";

import Login from "../UI/Auth/Login";
// import PaymentFavorite from '../UI/Payment/PaymentsFavorire';
// import PaymentFavoritePay from '../UI/Payment/PaymentsFavorirePay';
import MailBox from "../UI/MailBox";
import Payments from "../UI/Payments";
import Services from "../UI/Services";
import Profile from "../UI/Profile";

const MyRoutes = (props) => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/auth/login" element={<Login />} />
				<Route path="/payments" element={<Payments />} />
				<Route path="/payments/:page" element={<Payments />} />
				<Route path="/services" element={<Services />} />
				<Route path="/mailbox" element={<MailBox />} />
				<Route path="/mailbox/:page" element={<MailBox />} />
				<Route path="/about" element={<PageAbout />} />
				<Route path="/contact" element={<PageContact />} />
				<Route path="/profile" element={<Profile />} />
				<Route path="/profile/:page" element={<Profile />} />
				<Route path="*" element={<E404 />} />
			</Routes>
		</BrowserRouter>
	);
};

export default MyRoutes;
