import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import ApiServices from '../../APP/ApiServices';

import { useFetching } from '../../../hooks/useFetching';

import bg from '../../../assets/picture/auth/bg.png';
import LoginForm from './LoginForm';
import LoginCode from './LoginCode';
import SiteHeader from '../Layouts/SiteHeader';

import { useDispatch } from 'react-redux';
import { setToken, setUserDetails } from '../../../toolkitRedux/toolkitReducer';

const Login = (props) => {
	const navigate = useNavigate();
	const [cookies, setCookie] = useCookies();
	const dispatch = useDispatch();

	const [loginStep, setLoginStep] = useState(0);

	const [loginValues, setLoginValues] = useState({
		phone: '#',
		email: '',
		method: 'sms',
		code: '',
	});

	const [uuCode, setUUCode] = useState();

	const [fetching, isDataLoading, error] = useFetching(async (param) => {
		const response = await ApiServices.registerApi(param);
		return response;
	});

	const submitLoginForm = async (e) => {
		setLoginValues({
			...loginValues,
			phone: e.phone,
			email: e.email,
			method: e.method,
		});
		const pars = {
			function: 'send_verification_code',
			pars: {
				method: e.method,
				to_number: e.phone,
				to_email: e.email,
			},
		};
		// console.log(pars);
		const response = await fetching(pars);
		const respData = response.data;
		console.log(response);
		if (respData.success === 'success') {
			setUUCode(respData.uucode);
			setLoginStep(1);
		}
	};

	const submitCodeForm = async (e) => {
		setLoginValues({
			...loginValues,
			code: e.code,
		});
		let pars =
			loginValues.method === 'sms'
				? { phone: loginValues.phone }
				: { email: loginValues.value };
		pars = { ...pars, uuCode: uuCode, confirmCode: e.code };
		const request = {
			function: 'user_verify_then_add_simple',
			pars: pars,
		};
		const response = await fetching(request);
		const respData = response.data;
		if (respData.success === 'success') {
			// dispatch(setUserDetails(resp.data.data));
			const newToken = respData.token;
			const expires = new Date();
			expires.setDate(expires.getDate() + 30);
			setCookie('_ut_ipsg_', newToken, { path: '/', expires: expires });
			dispatch(setToken(newToken));
			dispatch(setUserDetails(respData.data));
			// props.handleClose();
			navigate('/');
		} else {
			// handle Error: incorrect code
		}
	};

	return (
		<div>
			<SiteHeader active="login" />
			<div
				id="edit-profile"
				className="view-wrapper is-webapp"
				data-page-title="ავტორიზაცია"
				data-naver-offset="214"
				data-menu-item="#auth"
				data-mobile-item="#home-sidebar-menu-mobile"
			>
				<div className="page-content-wrapper page-content is-relative page-content-inner account-wrapper inner-wrap promotion-page-wrapper">
					<div className="wrapper-outer br-16">
						<div className="wrapper-inner">
							<div className="action-box">
								<div className="box-content">
									<img className="light-image is-larger" src={bg} alt="" />
									<img className="dark-image is-larger" src={bg} alt="" />
								</div>
							</div>
						</div>
						{loginStep == 0 ? (
							<LoginForm
								loginValues={loginValues}
								submitLoginForm={submitLoginForm}
							/>
						) : (
							<LoginCode
								loginValues={loginValues}
								submitCodeForm={submitCodeForm}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default Login;
