import React from 'react';

import ReactTypingEffect from 'react-typing-effect';

import H01 from '../../../assets/picture/home/001.png';
import H02 from '../../../assets/picture/home/002.png';
import H03 from '../../../assets/picture/home/003.png';

import SiteHeader from '../Layouts/SiteHeader';

const ProfileSessionHistory = (props) => {
	return (
		<div>
			<SiteHeader active="home" />
			<div
				id="home"
				className="view-wrapper is-webapp"
				data-page-title="მთავარი"
				data-naver-offset="214"
				data-menu-item="#home"
				data-mobile-item="#home-sidebar-menu-mobile"
			>
				<div className="page-content-wrapper">
					<div className="page-content is-relative">
						<div className="page-content-inner">
							<div className="account-wrapper">
								<div className="hero-body">
									<div className="container mt-20 mb-20">
										<div className="columns is-vcentered">
											<div className="column is-7">
												<h1 className="title is-1 is-bold font">
													გადახდები ერთ სივრცეში
												</h1>

												<h3 className="subtitle is-4 pt-2 light-text font-p mt-5 mb-5">
													გადაიხადე
													<span className="ml-2 is-1 is-bold font">
														{/* <ReactTypingEffect 
                                                    text={[" ტელეფონი", "ტელევიზია", "ინტერნეტი", "კომუნალურები"]} 
                                                    className="subtitle is-4 pt-2 light-text font-p"
                                                    speed= "150"
                                                    eraseSpeed= "150"
                                                    eraseDelay= "500"
                                                    typingDelay= "500"
                                                    style = { color = 'magenta'}
                                                /> */}
														<ReactTypingEffect
															text={[
																' ტელეფონი',
																'ტელევიზია',
																'ინტერნეტი',
																'კომუნალურები',
																'ერთ სივრცეში!',
															]}
															speed="100"
															eraseSpeed="100"
															eraseDelay="500"
															typingDelay="500"
															cursorRenderer={(cursor) => <h1>{cursor}</h1>}
															displayTextRenderer={(text, i) => {
																return (
																	<h1>
																		{text.split('').map((char, i) => {
																			const key = `${i}`;
																			return (
																				<span
																					key={key}
																					style={
																						i % 1 === 0
																							? { color: '#a3a5b9' }
																							: {}
																					}
																					className={'font-p'}
																				>
																					{char}
																				</span>
																			);
																		})}
																	</h1>
																);
															}}
														/>
													</span>
												</h3>
												<div className="buttons mt-9">
													<a className="button h-button is-bold is-primary is-rounded is-raised font">
														ავტორიზაცია
													</a>
													{/* <a className="button h-button is-bold is-primary is-rounded  is-raised is-outlined font">ჩემი სერვისები</a> */}
												</div>
											</div>
											<div className="column is-5">
												<img
													className="light-image-l hero-mockup"
													src="/card001.png"
													alt=""
												/>

												{/* <img className="dark-image-l hero-mockup" src="assets/img/illustrations/landing/app-2-dark.png" alt=""/> */}
											</div>
										</div>

										<div className="page-content-inner">
											<div className="standard-onboarding mt-20">
												<div className="onboarding-wrap">
													<div className="onboarding-wrap-inner">
														<div className="onboarding-card br-16">
															<img className="light-image" src={H01} alt="" />
															<img className="dark-image" src={H01} alt="" />
															<p className="font-p">
																დარეგისტრირდი და ისარგებლე სხვადასხვა სერვისებით
															</p>
															<div className="button-wrap">
																<a className="button h-button is-primary is-elevated font is-rounded">
																	ავტორიზაცია
																</a>
															</div>
														</div>
														<div className="onboarding-card br-16">
															<img className="light-image" src={H02} alt="" />
															<img className="dark-image" src={H02} alt="" />
															<p className="font-p">
																გადაიხადე ინტერნეტი, ტელეფონი, ტელევიზია
																მომენტალურად
															</p>
															<div className="button-wrap">
																<a className="button h-button is-primary is-elevated font is-rounded">
																	გადახდები
																</a>
															</div>
														</div>
														<div className="onboarding-card br-16">
															<img className="light-image" src={H03} alt="" />
															<img className="dark-image" src={H03} alt="" />
															<p className="font-p">
																ისარგებლე პირადი კაბინეტით და მართე შენი
																გადახდები
															</p>
															<div className="button-wrap">
																<a className="button h-button is-primary is-elevated font is-rounded">
																	სერვისები
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileSessionHistory;
