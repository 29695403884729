import React, { useState } from 'react';
import { validateEmail } from '../../APP/email-validator';

import { Button } from '@mui/material';
import { PatternFormat } from 'react-number-format';

const LoginForm = (props) => {
	const loginValues = props.loginValues;
	const [phoneValue, setPhoneValue] = useState(loginValues.phoneValue);
	const [emailValue, setEmailValue] = useState(loginValues.emailValue);
	const [method, setMethod] = useState(loginValues.method);

	const [loginButtonOK, setLoginButtonOK] = useState(false);

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && loginButtonOK) {
			handleSubmit();
		}
	};

	const handleSubmit = (e) => {
		const newValues = {
			phone: phoneValue.replace(/[()+ ]/g, ''),
			email: emailValue,
			method: method,
		};
		props.submitLoginForm(newValues);
	};

	const handleEmailChange = (e) => {
		const value = e.target.value;
		setEmailValue(value);
		if (method === 'email' && validateEmail(value)) setLoginButtonOK(true);
		else setLoginButtonOK(false);
	};

	const handlePhoneChange = (e) => {
		const value = e.target.value;
		setPhoneValue(value);
		if (method === 'sms' && value.slice(-1) !== '#') setLoginButtonOK(true);
		else setLoginButtonOK(false);
	};

	const handleSetMethod = (e) => {
		const md = e.currentTarget.dataset.tab;
		setMethod(md);
		setLoginButtonOK(false);
		if (md === 'sms' && phoneValue.slice(-1) !== '#') setLoginButtonOK(true);
		if (md === 'email' && validateEmail(emailValue)) setLoginButtonOK(true);
	};

	return (
		<div className="side-wrapper">
			<div className="side-inner p-6">
				<h3 className="fs-24 font has-text-centered mb-3 title is-5 mt-5">
					სისტემაში შესვლა
				</h3>
				<div className="tabs-wrapper">
					<div className="tabs-inner ">
						<div className="tabs is-centered">
							<ul>
								<li
									data-tab="sms"
									className={method === 'sms' ? 'is-active' : ''}
									data-id="0"
									onClick={handleSetMethod}
								>
									<a>
										{' '}
										<i data-feather="smartphone"></i>{' '}
										<span className="font">მობილური</span>
									</a>
								</li>
								<li
									data-tab="email"
									className={method === 'email' ? 'is-active' : ''}
									data-id="1"
									onClick={handleSetMethod}
								>
									<a>
										<i data-feather="mail"></i>{' '}
										<span className="font">ელ-ფოსტა</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div id="sms" className={method === "sms" ? "tab-content is-active mt-5" : "tab-content"}>
						<div className="field">
							<div className="control has-icon mb-5">
								<PatternFormat
									className="input is-rounded font-p"
									onKeyPress={handleKeyPress}
									onChange={handlePhoneChange}
									format="(+995) 5## ######"
									mask="#"
									allowEmptyFormatting
									autoFocus
								/>
								<span className="form-icon">
									<i data-feather="smartphone"></i>
								</span>
							</div>
						</div>
					</div>
					<div id="email" className={method === "email" ? "tab-content is-active mt-5" : "tab-content"}>
						<div className="field">
							<div className="control has-icon mb-5">
								<input
									className="input is-rounded font-p"
									type="text"
									placeholder="ელ-ფოსტის მისამართი"
									onKeyPress={handleKeyPress}
									onChange={handleEmailChange}
								/>
								<span className="form-icon">
									<i data-feather="mail"></i>
								</span>
							</div>
						</div>
					</div>
				</div>
				<div className="control login">
					<Button
						disabled={!loginButtonOK}
						className="button h-button is-primary is-bold is-fullwidth is-raised is-rounded font mt-5"
						onClick={handleSubmit}
					>
						შესვლა / რეგისტრაცია
					</Button>
				</div>
			</div>
		</div>
	);
};
export default LoginForm;
