import React from 'react';

import PaymentNavbar from './Partials/PaymentNavbar';

const PaymentsFavorites = (props) => {
	return (
		<div className="column is-8">
			<div className="columns is-multiline">
				<div className="column is-4">
					<div className="s-card-advanced br-16">
						<div className="card-head">
							<div className="left">
								<div className="media-flex-center no-margin">
									<div className="h-icon is-primary bg-primary is-rounded text-light">
										2
									</div>
									<div className="flex-meta">
										<span className="font text-dark">ჯამურად გადახდა</span>
									</div>
								</div>
							</div>
						</div>
						<div className="pt-3 pb-3 has-text-centered text-dark">
							დავალიანება: <span className="text-danger">200</span> ₾
						</div>
						<div className="card-foot font-p ">
							<a
								href="/payments/favorites/pay"
								className="button h-button is-primary is-rounded is-elevated w-100"
							>
								<span className="icon">
									<i data-feather="credit-card"></i>
								</span>
								<span className="font">გადახდა</span>
							</a>
						</div>
					</div>
				</div>

				<div className="column is-4">
					<div className="s-card-advanced br-16">
						<div className="card-head">
							<div className="left">
								<div className="media-flex-center no-margin">
									<div className="h-avatar is-medium">
										<img
											className="avatar "
											src="https://via.placeholder.com/150x150"
											data-demo-src="/assets/img/avatars/photos/8.jpg"
											alt=""
										/>
									</div>
									<div className="flex-meta">
										<span className="font">ტელეკომ 1</span>
										<span className="font-p">სპს ინტელკომ სერვის ჯგუფი</span>
									</div>
								</div>
							</div>
						</div>
						<div className="pt-3 pb-3 has-text-centered">
							ავანსი: <span className="text-success">200</span> ₾
						</div>
						<div className="card-foot font-p has-text-centered">
							ქუთაისი, მაისურაძის 15/45
						</div>
					</div>
				</div>

				<div className="column is-4">
					<div className="s-card-advanced br-16">
						<div className="card-head">
							<div className="left">
								<div className="media-flex-center no-margin">
									<div className="h-avatar is-medium">
										<img
											className="avatar "
											src="https://via.placeholder.com/150x150"
											data-demo-src="/assets/img/avatars/photos/8.jpg"
											alt=""
										/>
									</div>
									<div className="flex-meta">
										<span className="font">ტელეკომ 1</span>
										<span className="font-p">სპს ინტელკომ სერვის ჯგუფი</span>
									</div>
								</div>
							</div>
						</div>
						<div className="pt-3 pb-3 has-text-centered">
							ავანსი: <span className="text-success">200</span> ₾
						</div>
						<div className="card-foot font-p has-text-centered">
							ქუთაისი, მაისურაძის 15/45
						</div>
					</div>
				</div>

				<div className="column is-4">
					<div className="s-card-advanced br-16">
						<div className="card-head">
							<div className="left">
								<div className="media-flex-center no-margin">
									<div className="h-avatar is-medium">
										<img
											className="avatar "
											src="https://via.placeholder.com/150x150"
											data-demo-src="/assets/img/avatars/photos/8.jpg"
											alt=""
										/>
									</div>
									<div className="flex-meta">
										<span className="font">ტელეკომ 1</span>
										<span className="font-p">სპს ინტელკომ სერვის ჯგუფი</span>
									</div>
								</div>
							</div>
						</div>
						<div className="pt-3 pb-3 has-text-centered">
							ავანსი: <span className="text-success">200</span> ₾
						</div>
						<div className="card-foot font-p has-text-centered">
							ქუთაისი, მაისურაძის 15/45
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentsFavorites;
