import React, { useEffect } from "react";
// import { BrowserRouter, Route, Routes } from 'react-router-dom';

// import SiteHeader from '../UI/Layouts/SiteHeader';
import BurgerMobileNav from "../UI/Layouts/BurgerMobileNav";
import BurgerMobileNavIcon from "../UI/Layouts/BurgerMobileNavIcon";
import BurgerMobileNavSlidebar from "../UI/Layouts/BurgerMobileNavSlidebar";
import LanguageChange from "../UI/Layouts/LanguageChange";
import RightBox from "../UI/Layouts/RightBox";
import Footer from "../UI/Layouts/Footer";

// import Login from '../UI/Auth/Login';
// import Fa2 from '../UI/Auth/2fa';

// import ProfileHome from '../UI/Profile/ProfileHome';
// import ProfileSessionHistory from '../UI/Profile/ProfileSessionHistory';

// import PaymentFavorite from '../UI/Payment/PaymentsFavorire';
// import PaymentFavoritePay from '../UI/Payment/PaymentsFavorirePay';
// import PaymentHome from '../UI/Payment';
// import PaymentShow from '../UI/Payment/PaymentShow';
// import PaymentHistory from '../UI/Payment/PaymentsHistory';

// import MailBoxHome from '../UI/MailBox';

// import Home from '../UI/Home/Home';
// import PageAbout from '../UI/Pages/About';
// import PageContact from '../UI/Pages/Contact';
// import E404 from '../UI/Errors/404';

// import { loadUserData } from './userData';

import MyRoutes from "./MyRoutes";

import { useCookies } from "react-cookie";

import { useDispatch } from "react-redux";
import { setToken, setUserBusiness } from "../../toolkitRedux/toolkitReducer";
import { setUserDetails } from "../../toolkitRedux/toolkitReducer";
import ApiServices from "./ApiServices";

function App() {
	const [cookies, setCookie, removeCookie] = useCookies();
	const dispatch = useDispatch();

	const loadUserData = async () => {
		let token = await cookies["_ut_ipsg_"];
		if (!token) token = "unauth";
		dispatch(setToken(token));
		if (token !== "unauth") {
			const request = {
				function: "getUserData",
				token: token,
			};
			const response = await ApiServices.userApi(request);
			if (response.data.success === "success") {
				dispatch(setUserDetails(response.data.data));
				if (sessionStorage.getItem("userBusiness")) {
					dispatch(
						setUserBusiness(JSON.parse(sessionStorage.getItem("userBusiness")))
					);
				}
			} else if (response.data.success === "fail") {
				removeCookie("_ut_ipsg_");
				dispatch(setToken("unauth"));
				dispatch(setUserDetails({}));
				dispatch(setUserBusiness(false));
				sessionStorage.removeItem("userBusiness");
			} else {
				// Error message: something went wrong
				// await this.signOut();
			}
		}
	};

	useEffect(() => {
		loadUserData();
	}, []);

	return (
		<div id="huro-app" className="app-wrapper font">
			<div className="app-overlay"></div>
			<div className="pageloader is-full"></div>
			{/* <div className="infraloader is-full is-active"></div> */}
			{/* <BurgerMobileNav />
			<BurgerMobileNavIcon />
			<BurgerMobileNavSlidebar /> */}

			<MyRoutes />
			{/* <LanguageChange/> */}

			{/* <RightBox/> */}

			{/* <BrowserRouter>
				<Routes>
					<Route path="/" element={<Home />} />

					<Route path="/auth/login" element={<Login />} />
					<Route path="/auth/2fa" element={<Fa2 />} />

					<Route path="profile" element={<ProfileHome />} />
					<Route
						path="profile/session-history"
						element={<ProfileSessionHistory />}
					/>

					<Route path="payment" element={<PaymentHome />} />
					<Route path="payment/favorite" element={<PaymentFavorite />} />
					<Route path="payment/favorite/pay" element={<PaymentFavoritePay />} />
					<Route path="payment/show" element={<PaymentShow />} />
					<Route path="payments/:id" element={<PaymentHome />} />
					<Route path="payment/history" element={<PaymentHistory />} />

					<Route path="mail" element={<MailBoxHome />} />

					<Route path="about" element={<PageAbout />} />
					<Route path="contact" element={<PageContact />} />

					<Route path="*" element={<E404 />} />
				</Routes>
			</BrowserRouter> */}

			<Footer />
		</div>
	);
}

export default App;
