import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import HeaderProfileDropdown from './Partials/HeaderProfileDropdown';
// import HeaderNoAuth from './Partials/HeaderNoAuth';

import Logo from '../../../assets/picture/logo/l005.png';
import LogoDark from '../../../assets/picture/logo/l006.png';
import SignInBooble from './Partials/SignInBooble';
import ApiServices from './../../APP/ApiServices';

import {
	IconHome2,
	IconLayoutGrid,
	IconServer,
	IconMail,
	IconSearch,
} from '@tabler/icons-react';

const SiteHeader = (props) => {
	const token = useSelector((state) => state.toolkit.token);
	const active = props.active;
	// console.log('active', active);
	return (
		<div className="webapp-navbar is-transparent">
			<div className="webapp-navbar-inner">
				<div className="left">
					<Link to="/" className="brand">
						<img className="light-image" src={Logo} alt="" />
						<img className="dark-image" src={LogoDark} alt="" />
					</Link>
					<div className="separator"></div>
					<h1 id="webapp-page-title" className="title is-5 font-p">
						Welcome
					</h1>
				</div>
				<div className="center">
					<div id="webapp-navbar-menu" className="centered-links">
						<Link
							to="/"
							className={
								active === 'home' ? 'centered-link is-active' : 'centered-link'
							}
						>
							<IconHome2 />
							<span className="font">მთავარი</span>
						</Link>
						<Link
							to="/payments"
							className={
								active === 'payments'
									? 'centered-link is-active'
									: 'centered-link'
							}
						>
							<IconLayoutGrid />
							<span className="font">გადახდები</span>
						</Link>
						<Link
							to="/services"
							className={
								active === 'services'
									? 'centered-link is-active'
									: 'centered-link'
							}
						>
							<IconServer />
							<span className="font">ჩემი სერვისები</span>
						</Link>
						<Link
							to="/mailbox"
							className={
								active === 'mailbox'
									? 'centered-link is-active'
									: 'centered-link'
							}
						>
							<IconMail />
							<span className="font">საფოსტო ყუთი</span>
						</Link>

						<Link className="centered-link centered-link-search">
							<IconSearch />
							<span className="font">ძებნა</span>
						</Link>
					</div>
					<div id="webapp-navbar-search" className="centered-search is-hidden">
						<div className="field">
							<div className="control has-icon">
								<input
									type="text"
									className="input is-rounded"
									placeholder="მოძებნე შენი პროვაიდერი..."
								/>
								<div className="form-icon">
									<i data-feather="search"></i>
								</div>
								<div
									id="webapp-navbar-search-close"
									className="form-icon is-right"
								>
									<i data-feather="x"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="right">
					<div className="toolbar ml-auto">
						<div className="toolbar-link">
							<label className="dark-mode ml-auto">
								<input type="checkbox" defaultChecked={true}></input>
								<span></span>
							</label>
						</div>
						<Link
							className="toolbar-link right-panel-trigger"
							data-panel="languages-panel"
						>
							<img src="/assets/img/icons/flags/en.png" alt="" />
						</Link>
						<div className="toolbar-notifications is-hidden-mobile">
							<div className="dropdown is-spaced is-dots is-right dropdown-trigger">
								<div className="is-trigger" aria-haspopup="true">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="32"
										height="32"
										viewBox="0 0 24 24"
										strokeWidth="2"
										stroke="currentColor"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<path d="M6 5a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-8a2 2 0 0 1 -2 -2v-14z"></path>
										<path d="M11 4h2"></path>
										<path d="M12 17v.01"></path>
									</svg>
								</div>
							</div>
						</div>
					</div>
					{token !== 'unauth' ? (
						<HeaderProfileDropdown />
					) : props.active !== 'login' ? (
						<SignInBooble />
					) : (
						<></>
					)}
				</div>
			</div>
		</div>
	);
};

export default SiteHeader;
