import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'dayjs';

import { DatePicker, Space } from 'antd';

import 'dayjs/locale/ka';
import locale from 'antd/es/date-picker/locale/ka_GE';

import { useFetching } from '../../../hooks/useFetching';
import ApiService from '../../APP/ApiServices';

import {
	IconBrandApple,
	IconBrandWindows,
	IconBrandUbuntu,
	IconBrandAndroid,
	IconQuestionMark,
} from '@tabler/icons-react';

const ProfileSessionHistory = (props) => {
	const [sessions, setSessions] = useState([]);
	const [dateRange, setDateRange] = useState([dayjs().add(-14, 'd'), dayjs()]);

	const token = useSelector((state) => state.toolkit.token);

	const { RangePicker } = DatePicker;

	const [fetching, isLoading, error] = useFetching(async (param) => {
		const response = await ApiService.userApi(param);
		return response;
	});

	const loadSessionsHistory = async () => {
		const request = {
			function: 'getSessionsHistory',
			token: token,
			pars: {
				start: dateRange[0].format('YYYY-MM-DD'),
				stop: dateRange[1].format('YYYY-MM-DD'),
			},
		};
		const response = await fetching(request);
		if ((response.data.success = 'success')) {
			setSessions(response.data.data);
		}
	};

	useEffect(() => {
		if (token && token !== 'undefined') loadSessionsHistory();
	}, [token]);

	useEffect(() => {
		if (token && token !== 'undefined') loadSessionsHistory();
	}, [dateRange]);

	const onRangeChange = (dates, dateStrings) => {
		if (dates) {
			setDateRange(dates);
		}
	};

	const rangePresets = [
		{
			label: 'ბოლო 14 დღე',
			value: [dayjs().add(-14, 'd'), dayjs()],
		},
		{
			label: 'ბოლო 30 დღე',
			value: [dayjs().add(-30, 'd'), dayjs()],
		},
		{
			label: 'ბოლო 90 დღე',
			value: [dayjs().add(-90, 'd'), dayjs()],
		},
		{
			label: 'ბოლო 120 დღე',
			value: [dayjs().add(-120, 'd'), dayjs()],
		},
	];

	const brandLogo = (os) => {
		switch (os.toLowerCase()) {
			case 'windows':
				return <IconBrandWindows />;
			case 'macintosh':
				return <IconBrandApple />;
			case 'linux':
				return <IconBrandUbuntu />;
			case 'android':
				return <IconBrandAndroid />;
			case 'ios':
				return <IconBrandApple />;
			case 'windows phone':
				return <IconBrandWindows />;
			default:
				return <IconQuestionMark />;
		}
	};

	return (
		<div className="column is-8">
			<div className="account-box is-form is-footerless">
				<div className="form-head stuck-header">
					<div className="form-head-inner">
						<div className="left">
							<h3 className="font">შესვლის ისტორია</h3>
						</div>
						<div className="right">
							<div className="buttons">
								<button
									id="save-button"
									className="button h-button is-success is-raised font is-rounded"
								>
									{' '}
									<i className="fas fa-file-excel mr-3"></i> გადმოწერა
								</button>
							</div>
						</div>
					</div>
				</div>
				<div className="form-body">
					<div className="fieldset">
						<div className="setting-list">
							<Space direction="vertical" size={12}>
								<RangePicker
									locale={locale}
									format="DD/MM/YYYY"
									presets={rangePresets}
									defaultValue={dateRange}
									onChange={onRangeChange}
								/>
							</Space>
							<p className="mb-5"></p>
							{sessions?.map((data, i) => (
								<div className="setting-item profile-session" key={i}>
									{brandLogo(data.os)}
									<div className="meta">
										<span className="dark-inverted">{data.os}</span>
										<span>
											<span>{data.user_ip}</span>
											<i aria-hidden="true" className="fas fa-circle"></i>
											<span>
												{dayjs(data.time_login).format('DD/MM/YYYY HH:mm')}
											</span>
										</span>
										<span>{data.browser}</span>
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProfileSessionHistory;
