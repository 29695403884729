import React from 'react';



const PageAbout = (props) => {
	
	return (
		
       <div>
        a
       </div>
	);
};

export default PageAbout;