import React from 'react';

import PaymentNavbar from './Partials/PaymentNavbar';

const PaymentFavoritePay = (props) => {
	const payList = [
		{
			logo: 'https://via.placeholder.com/150x150',
			name: 'ტელეკომ 1',
			desc: 'ინტერნეტი, ტელეფონი, ტელევიზია',
			price: '',
		},
		{
			logo: 'https://via.placeholder.com/150x150',
			name: 'დატაკომი',
			desc: 'ინტერნეტი',
			price: '',
		},
		{
			logo: 'https://via.placeholder.com/150x150',
			name: 'ტელეკომ 1',
			desc: 'ინტერნეტი, ტელეფონი, ტელევიზია',
			price: '',
		},
		{
			logo: 'https://via.placeholder.com/150x150',
			name: 'ტელეკომ 1',
			desc: 'ინტერნეტი, ტელეფონი, ტელევიზია',
			price: '',
		},
	];

	return (
		<div className="column is-8">
			<div className="columns is-multiline">
				<div className="column is-12">
					<div className="s-card-advanced br-16">
						<div className="card-head">
							<div className="left">
								<div className="media-flex-center no-margin">
									<div className="h-icon is-danger is-rounded ">2</div>
									<div className="flex-meta">
										<span className="font">ჯამურად გადახდა</span>
									</div>
								</div>
							</div>
						</div>
						<div className="pt-3 pb-3 has-text-centered">
							<table className="table is-hoverable is-fullwidth">
								<tbody>
									<tr>
										<th className="font"></th>
										<th className="font"></th>
										<th className="font">დასახელება</th>
										<th className="font">აღწერა</th>
										<th className="font">თანხა</th>
									</tr>

									{payList.map((data) => (
										<tr>
											<td className="font-p">
												<label class="checkbox">
													<input type="checkbox" checked />
													<span></span>
												</label>
											</td>
											<td className="font-p">
												<div className="h-avatar is-medium">
													<img
														className="avatar "
														src={data.logo}
														data-demo-src={data.logo}
														alt=""
													/>
													<img className="badge" src={data.logo}></img>
												</div>
											</td>
											<td className="font-p">{data.name}</td>
											<td className="font-p">{data.desc}</td>
											<td className="font-p">
												<div className="field has-addons">
													<div className="control is-expanded">
														<input
															className="input is-rounded font-p"
															type="text"
															placeholder="თანხა"
														/>
													</div>
													<div className="control">
														<a className="button  is-rounded font">₾</a>
													</div>
												</div>
											</td>
										</tr>
									))}
								</tbody>
							</table>
						</div>
						<div class="card-foot">
							<div class="left">
								<div class="tags">საკომისიო: 0.50 ₾</div>
							</div>
							<div class="right">
								<button class="button is-primary is-raised is-rounded is-elevated">
									<span className="icon">
										<i data-feather="credit-card"></i>
									</span>
									<span className="font">გადახდა</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default PaymentFavoritePay;
